import dynamic from 'next/dynamic';

import { FieldAttributes, FieldConfig } from 'formik';

const Select = dynamic(
  import('react-select').then(mod => mod.default),
  { ssr: false },
);

export default function SelectField({
  options,
  field,
  form,
  className = '',
  placeholder = '',
  afterChange,
  beforeChange,
  isLoading,
  noOptionsMessage,
  loadingMessage,
  key,
  tabIndex
}: any) {
  const onChange = (option: any) => {
    typeof beforeChange === 'function'
      ? beforeChange({ form, field, option })
      : () => { };

    form.setFieldValue(field.name, option.value);
    typeof afterChange === 'function' ? afterChange(option) : () => { };

    setTimeout(() => {
      form.validateField(field.name);
    }, 600);
  };

  return (
    <Select
      key={key || undefined}
      className={`react-select-container ${className}`}
      classNamePrefix="react-select"
      options={options}
      name={field.name}
      value={
        options
          ? options.find((option: any) => option.value === field.value)
          : ''
      }
      onChange={onChange}
      onBlur={field.onBlur}
      placeholder={placeholder}
      isLoading={isLoading || false}
      noOptionsMessage={
        noOptionsMessage ? noOptionsMessage : () => 'Sem opções'
      }
      loadingMessage={loadingMessage ? loadingMessage : () => 'Carregando...'}
      tabIndex={tabIndex}
    />
  );
}
