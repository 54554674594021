import Link from 'next/link';
import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react';

import styles from 'styles/Button.module.css';

type LinkPropsType = Partial<AnchorHTMLAttributes<HTMLAnchorElement>>;
type ButtonPropsType = Partial<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

type ButtonProps = PropsWithChildren<
  {
    maxWidth?: string;
    variant?: 'white' | 'error' | 'success' | 'danger' | 'warning' | 'gray';
    hasRightIcon?: boolean;
    hasLeftIcon?: boolean;
    className?: string;
    isLoading?: boolean;
    disabled?: boolean;
    as?: 'a' | 'link';
  } & LinkPropsType &
  ButtonPropsType
>;

const Button: React.FC<ButtonProps> = ({
  children,
  maxWidth,
  className,
  variant = 'success',
  hasRightIcon,
  hasLeftIcon,
  isLoading,
  disabled,
  ...props
}) => {
  return (
    <>
      {props.as === 'a' || props.as === 'link' ? (
        <Link
          href={props.href as string}
          legacyBehavior
        >
          <a
            target={props.target ? props.target : '_self'}
            className={`${styles.button} ${styles[variant]} ${hasRightIcon ? styles['isIconRight'] : ''
              } ${hasLeftIcon ? styles['iconLeft'] : ''} ${className}`}
            style={{ pointerEvents: disabled || isLoading ? 'none' : 'initial' }}
          >
            {children}
          </a>
        </Link>
      ) : (
        <button
          {...props}
          type={props.type ? props.type : 'button'}
          className={`${styles.button} ${styles[variant]} ${hasRightIcon ? styles['isIconRight'] : ''
            } ${hasLeftIcon ? styles['iconLeft'] : ''} ${className}`}
          disabled={disabled || isLoading}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
